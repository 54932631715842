import React, { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { Dropdown, Layout, Menu } from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";
import styled from "styled-components";
import useLogout from "components/login/hooks/useLogout";
import { mdiPillar, mdiTimetable } from "@mdi/js";
import Icon from "@mdi/react";

interface GlobalLayoutProps {
  children: React.ReactElement;
}

const GlobalLayout: React.FC<GlobalLayoutProps> = ({ children }) => {
  const authContext = useContext(AuthContext);
  const { logout } = useLogout();

  const navigate = useNavigate();

  const items = [
    {
      key: "timesheet",
      label: (
        <NavigationItem>
          <Icon path={mdiTimetable} size={0.8} />
          Timesheet
        </NavigationItem>
      ),
      onClick: () => navigate("/")
    },{
      key: "pillars",
      label: (
        <NavigationItem>
          <Icon path={mdiPillar} size={0.8} />
          Pillars
        </NavigationItem>
      ),
      onClick: () => navigate("/pillars")
    }
  ];

  const handleLogout = () => {
    logout();
  };

  const userMenu = [
    {
      key: "employeeId",
      label: (
        <NonClickableMenuItem>{`Bamboo Employee ID: ${authContext?.user?.employeeId}`}</NonClickableMenuItem>
      )
    },
    {
      key: "logout",
      label: "Logout",
      onClick: handleLogout
    }
  ];

  return (
    <StyledLayout>
      <StyledHeader>
        <AppTitle>Turing Util</AppTitle>
        <div>
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={["timesheet"]}
            items={items}
          />
        </div>
        <Dropdown menu={{ items: userMenu }} trigger={["click"]}>
          <AuthInfo>{authContext?.user?.email}</AuthInfo>
        </Dropdown>
      </StyledHeader>
      <StyledContent>{children}</StyledContent>
      <StyledFooter>Turing Analytics Inc &copy;{new Date().getFullYear()}</StyledFooter>
    </StyledLayout>
  );
};

export default GlobalLayout;

const StyledLayout = styled(Layout)`
  min-height: 100vh;
`;

const StyledHeader = styled(Header)`
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
`;

const AppTitle = styled.div`
  color: #fff;
  font-size: 1.5rem;
  margin-right: 25px;
`;

const AuthInfo = styled.div`
  cursor: pointer;
  color: #fff;
`;

const StyledContent = styled(Content)`
  padding: 24px;
  margin: 24px;
`;

const StyledFooter = styled(Footer)`
  text-align: center;
  background-color: #001529;
  color: #fff;
  padding: 10px;
`;

const NonClickableMenuItem = styled.div`
  cursor: default;
`;

const NavigationItem = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
