import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { AuthProvider } from "./contexts/AuthContext";
import { Login } from "./components/login";
import { ConfigProvider } from "antd";
import PrivateRoute from "./PrivateRoute";
import { Pillars, Timesheet } from "./components";
import { QueryClient, QueryClientProvider } from "react-query";
import { azureAppClientId, azureAuthority, redirectUri } from "api";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

const queryClient = new QueryClient();

const msalConfig = {
  auth: {
    clientId: azureAppClientId,
    authority: azureAuthority,
    redirectUri: redirectUri
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false
  }
};
const msalInstance = new PublicClientApplication(msalConfig);

function App() {
  return (
    <ConfigProvider theme={{ token: { colorPrimary: "#00c7b1" } }}>
      <MsalProvider instance={msalInstance}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <Router>
              <Routes>
                <Route path="/" element={<PrivateRoute element={<Timesheet />} />} />
                <Route path="/pillars" element={<PrivateRoute element={<Pillars />} />} />
                <Route path="/login" element={<Login />} />
                <Route path="/callback" element={<Login />} />
              </Routes>
            </Router>
          </AuthProvider>
        </QueryClientProvider>
      </MsalProvider>
    </ConfigProvider>
  );
}

export default App;
