import { useState } from "react";
import styled from "styled-components";
import { Table, Typography, Tag, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import pillars from "../constants/pillars.json";

const { Title } = Typography;

type Pillar = {
  department: string;
  pillar: string;
  description: string;
  responsibility: string;
};

const PillarTable = () => {
  const [hiddenDepartments, setHiddenDepartments] = useState<string[]>([]);

  const handleHideDepartment = (department: string) => {
    setHiddenDepartments([...hiddenDepartments, department]);
  };

  const handleRemoveFilter = (department: string) => {
    setHiddenDepartments(hiddenDepartments.filter((dep) => dep !== department));
  };

  // Group pillars by department
  const groupedPillars = (pillars as Pillar[]).reduce((acc, pillar) => {
    if (!acc[pillar.department]) {
      acc[pillar.department] = [];
    }
    acc[pillar.department].push(pillar);
    return acc;
  }, {} as { [key: string]: Pillar[] });

  const columns = [
    {
      title: "Pillar",
      dataIndex: "pillar",
      key: "pillar"
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description"
    },
    {
      title: "Responsibility",
      dataIndex: "responsibility",
      key: "responsibility"
    }
  ];

  return (
    <Container>
      <div>
        {hiddenDepartments.map((department) => (
          <Tag key={department} closable onClose={() => handleRemoveFilter(department)}>
            {department}
          </Tag>
        ))}
      </div>
      <DepartmentsContainer>
        {Object.keys(groupedPillars)
          .filter((department) => !hiddenDepartments.includes(department))
          .map((department) => (
            <DepartmentContainer key={department}>
              <DepartmentHeader>
                <Title level={3}>{department}</Title>
                <CloseButton
                  type="text"
                  icon={<CloseOutlined />}
                  onClick={() => handleHideDepartment(department)}
                />
              </DepartmentHeader>
              <Table
                columns={columns}
                dataSource={groupedPillars[department]}
                rowKey="pillar"
                pagination={false}
              />
            </DepartmentContainer>
          ))}
      </DepartmentsContainer>
    </Container>
  );
};

export default PillarTable;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const DepartmentsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const DepartmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 300px;
  position: relative;
`;

const DepartmentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled(Button)`
  margin-left: 10px;
`;
