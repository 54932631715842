import styled from "styled-components";
import PillarTable from "./components/PillarTable";
import { mdiPillar } from "@mdi/js";
import Icon from "@mdi/react";
import { Content } from "antd/es/layout/layout";

const Pillars = () => {
  return (
    <StyledContent>
      <Header>
        <TitleWrapper>
          <Icon path={mdiPillar} size={1} />
          {"Pillars"}
        </TitleWrapper>
        <ActionsWrapper></ActionsWrapper>
      </Header>
      <PillarTable />
    </StyledContent>
  );
};

export default Pillars;

const StyledContent = styled(Content)``;

const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
`;

const TitleWrapper = styled.h2`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;
